<script>
import round from 'lodash/round';
import accounting from 'accounting';

export default {
    props: {
        stripePublishableKey: {
            type: String,
            required: true,
        },
        companyName: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            stripeHandler: null,
            configureInterval: null,
            amount: null,
            currency: 'cad',
            email: null,
            stripeToken: null,
        }
    },
    mounted () {
        this.configureInterval = setInterval(() => {
            if (StripeCheckout) {
                this.configureStripe();
            }
        }, 250);

        this.$refs.amountField.focus();
    },
    methods: {
        configureStripe () {
            clearInterval(this.configureInterval);

            this.stripeHandler = StripeCheckout.configure({
                key: this.stripePublishableKey,
                image: '/images/logo.svg',
                locale: 'cad',
                billingAddress: true,
                token: this.finishPayment,
                closed : this.cancelPayment,
                allowRememberMe: false
            });
        },

        startPayment () {
            let amountCents = round(parseFloat(this.amount) * 100);

            // $5
            if (amountCents < 500) {
                alert('Please enter an amount greater than $5.00');
                return;
            }

            this.$refs.amountField.readOnly = true;
            this.$refs.submitButton.readOnly = true;

            let description = 'Payment of '+accounting.formatMoney(amountCents / 100);

            this.stripeHandler.open({
                name: this.companyName,
                description: description,
                currency: this.currency || 'cad',
                amount: amountCents
            });
        },
        cancelPayment () {
            if (!this.stripeToken) {
                this.$refs.amountField.readOnly = false;
                this.$refs.submitButton.readOnly = false;
            }
        },
        finishPayment (token) {
            this.stripeToken = token.id;
            this.email = token.email;

            this.$nextTick(function () {
                this.$refs.form.submit();
            });
        },
    },
}
</script>
