import 'es6-promise/auto';

import Vue from 'vue';

import svg_icons from './common/svg_icons.vue';
import payment_form from './public/payment_form/index.vue';

// SASS/CSS
import '../../css/sass/public.scss';

// images
import '../../images/icons-public.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    components: {
        'svg-icons': svg_icons,
        'honiva-payment-form': payment_form
    }
});